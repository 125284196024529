import { getTemplatedAppNameOrDefault } from 'utils/getTemplatedAppNameOrDefault';

declare var window: any;

export const appConfig = {
  appName: getTemplatedAppNameOrDefault('SWD Reference Frontend SPA'),
  baseUrl: `https://gateway.fedsa-nonprod.np.apaas.inter.ikea.net/90/fedsa-dp-customer-management/v1/`,
  manualUrl: `https://gateway.fedsa-nonprod.np.apaas.inter.ikea.net/90/fedsa-api-customer-management/v1/`,
  customerEndpoint:
    window?.env?.CUSTOMER_BACKEND_ENDPOINT || process.env.REACT_APP_CUSTOMER_BACKEND_ENDPOINT,
  appClientId: window?.env?.CLIENT_ID || process.env.REACT_APP_CLIENT_ID,
  appTenantId: window?.env?.TENANT_ID || process.env.REACT_APP_TENANT_ID,
  appScope: window?.env?.SCOPE || process.env.REACT_APP_SCOPE,
  appInsightKey: window?.env?.INSIGHTS_KEY || process.env.REACT_APP_INSIGHTS_KEY,
  version: 'v0.22',
};

if (!Object.values(appConfig).every((x) => x !== null && x !== '' && x !== undefined)) {
  throw Error(`Missing value for env variables in env-config`);
}
