import { callAPI } from 'Action';
import { appConfig } from 'appConfig';
import { MANUAL_SUBMIT_URL } from './PathConstants';
import { MANUAL_SUBMIT, MANUAL_SUCCESS } from './types';

export const manualSubmit = (value: any) => async (dispatch: any) => {
  let url = appConfig.baseUrl + MANUAL_SUBMIT_URL;
  let reqObj = value;

  const options = {
    url,
    actionType: MANUAL_SUBMIT,
    method: 'POST',
    reqObj,
    dispatch,
  };
  let data = await callAPI(options);
  if (data) {
    dispatch({
      type: MANUAL_SUCCESS,
      payload: data,
    });
  }
};
export const resetManualEntry = () => (dispatch: any) => {
  dispatch({
    type: 'RESET_ERRORS',
  });
  dispatch({ type: MANUAL_SUBMIT, payload: null });
  dispatch({ type: MANUAL_SUCCESS, payload: null });
};
