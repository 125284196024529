import { Page, PageFEDSA } from 'components/layout';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import Text from '@ingka/text';
import React from 'react';
import Search from '@ingka/search';
import styled from 'styled-components';
import { contentDelimiterStyles } from 'components/layout';
import { DataTable } from 'components/common/DataTable';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/store';
import TableLayout from 'components/SharedLayout/TableLayout/tableLayout';
import PendingApproval from 'pages/PendingApproval/pendingApproval';
import SaveForLater from 'pages/SaveForLater/saveForLater';
import SubmitRegistery from 'pages/SubmitRegistery/submitRegistery';
import Archived from 'pages/Archived/archived';
import Button from '@ingka/button';
import { useNavigate } from 'react-router-dom';

const FileUpload = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10%;
`;
export const DetailPage = () => {
  const { fileUploadData, fileUploadStatus } = useSelector(
    (state: RootState) => state.fileUploadDetails
  );
  const { error, errorMessage } = useSelector((state: RootState) => state.error);
  const navigate = useNavigate();

  const tabs = [
    <Tab key="tab-3" text={'Pending Approval'} tabPanelId="tab_3" />,
    <Tab key="tab-4" text={'Save for Later'} tabPanelId="tab_4" />,
    <Tab key="tab-5" text={'Submitted to EU Registry'} tabPanelId="tab_5" />,
    <Tab key="tab-6" text={'Archived'} tabPanelId="tab_6" />,
  ];

  const tabPanels = [
    <TabPanel key="tab-3" tabPanelId="tab_3">
      <PendingApproval />
    </TabPanel>,
    <TabPanel key="tab-4" tabPanelId="tab_4">
      <SaveForLater />
    </TabPanel>,
    <TabPanel key="tab-5" tabPanelId="tab_5">
      <SubmitRegistery />
    </TabPanel>,
    <TabPanel key="tab-6" tabPanelId="tab_6">
      <Archived />
    </TabPanel>,
  ];
  const onSearch = (e: any) => {
    console.log(e);
  };

  const onClick = () => {
    navigate('/fileupload');
  };
  return (
    <PageFEDSA>
      <FileUpload>
        <Text headingSize="xl" tagName="h1">
          FEDSA
        </Text>
        <Button
          style={{ marginTop: '20px' }}
          text="Upload Submission"
          type="emphasised"
          size="small"
          onClick={onClick}
        />
      </FileUpload>
      <div>
        <Tabs
          tabs={tabs}
          tabPanels={tabPanels}
          defaultActiveTab="tab_3"
          ariaLabel="Example Tabs Label"
        />
      </div>
    </PageFEDSA>
  );
};
