import React, { useRef, useState, useEffect } from 'react';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import InputField from '@ingka/input-field';
import RadioButtonGroup from '@ingka/radio-button-group';
import './product.scss';
import Button from '@ingka/button';
import Accordion, { AccordionItem } from '@ingka/accordion';
import '@ingka/svg-icon/style.scss';
import '@ingka/accordion/style.scss';
import '@ingka/focus/style.scss';

const Products = (props: any) => {
  // const [formRows, setFormRows] = useState([
  //   { productName: '', gtinNumber: 'ON', articleNumber: '' },
  // ]);

  const [displayRows, setDisplayRows] = useState(false);
  const [previousRows, setPreviousRows] = useState([]);

  const prevProps = useRef();
  useEffect(() => {
    // Store current props in ref
    prevProps.current = props;
  });
  const previousProps = prevProps.current;
  const handleAddRow = () => {
    setPreviousRows([...previousRows, ...props.productRows]);
    props.setproductRows([{ productName: '', productId: '', productIdType: '' }]);
    setDisplayRows(true);
  };
  // const handleDeleteProducerRow = (index: number) => {
  //   if (props.productRows) {
  //     const newProducerRows = props.productRows?.slice(0, -1);
  //     props.setproductRows(newProducerRows);
  //   }
  // };
  const handleDeleteRow = (index) => {
    const newPreviousRows = previousRows.filter((_, i) => i !== index);
    setPreviousRows(newPreviousRows);
  };

  const handleInputChange = (index: number, field: any, value: any) => {
    const newFormRows = props.productRows?.map((row: any, i: any) =>
      i === index ? { ...row, [field]: value } : row
    );
    props.setproductRows([...newFormRows]);
  };
  const handleRadioChange = (index: number, productIdType: any) => {
    const newFormRows = props.productRows?.map((row: any, i: any) =>
      i === index ? { ...row, productIdType } : row
    );
    // props.productDetails([...newFormRows]);
    props.setproductRows([...newFormRows]);
  };
  console.log(props.productRows, 'dfg Inside');
  return (
    <div>
      <Accordion>
        <div className="maindiv" style={{ height: 'auto' }}>
          <AccordionItem title="Product Details" id="productaccordion">
            {props.productRows?.map((row, index) => (
              <div className="product-tabs" key={index}>
                <InputField
                  style={{ marginRight: 10 }}
                  iconPosition="leading"
                  id={`product-name-${index}`}
                  type="text"
                  value={row.productName}
                  onChange={(e) => handleInputChange(index, 'productName', e.target.value)}
                  label={index === 0 ? 'Product Name' : ''}
                />
                <InputField
                  style={{ marginRight: 10 }}
                  iconPosition="leading"
                  id={`article-number-${index}`}
                  type="text"
                  value={row.productId}
                  onChange={(e) => handleInputChange(index, 'productId', e.target.value)}
                  label={index === 0 ? 'Product Identifier' : ''}
                />
                <RadioButtonGroup
                  list={[
                    {
                      id: `GTINRadio-${index}`,
                      name: `groupRadios-${index}`,
                      label: 'GTIN number',
                      value: 'GTIN',
                      disabled: false,
                      checked: row.productIdType === 'GTIN',
                      onChange: () => handleRadioChange(index, 'GTIN'),
                    },
                    {
                      id: `secondRadio-${index}`,
                      name: `groupRadios-${index}`,
                      label: 'Article Number',
                      value: 'IAN',
                      disabled: false,
                      checked: row.productIdType === 'IAN',
                      onChange: () => handleRadioChange(index, 'IAN'),
                    },
                  ]}
                />
                {index === 0 && (
                  <>
                    <Button onClick={handleAddRow} size="xsmall">
                      +
                    </Button>
                    {/* <Button
                  onClick={() => handleDeleteProducerRow(index)}
                  size="xsmall"
                  type="emphasised"
                >
                  -
                </Button> */}
                  </>
                )}
              </div>
            ))}
            {displayRows &&
              previousRows?.map((row, index) => (
                <div className="text" key={index} style={{ fontSize: 15, fontWeight: 800 }}>
                  {row.productName} {row.productId} [{row.productIdType}]
                  <Button onClick={handleDeleteRow} size="xsmall">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="m12.0006 13.4148 2.8283 2.8283 1.4142-1.4142-2.8283-2.8283 2.8283-2.8283-1.4142-1.4142-2.8283 2.8283L9.172 7.7578 7.7578 9.172l2.8286 2.8286-2.8286 2.8285 1.4142 1.4143 2.8286-2.8286z"
                        fill="#111"
                      />
                    </svg>
                  </Button>
                </div>
              ))}
          </AccordionItem>
        </div>
      </Accordion>
    </div>
  );
};

export default Products;
