import { FILE_UPLOAD, FILE_UPLOAD_SUCESSFULLY } from 'Action/types';

const initialState = {
  fileUploadData: null,
  fileUploadStatus: null,
};

export default (state: any, action: any) => {
  if (!state) {
    state = initialState;
  }

  switch (action.type) {
    case FILE_UPLOAD:
      return {
        ...state,
        fileUploadData: action.payload,
      };

    case FILE_UPLOAD_SUCESSFULLY:
      return {
        ...state,
        fileUploadStatus: action.payload,
      };
    default:
      return state;
  }
};
