import { callAPI } from 'Action';
import { appConfig } from 'appConfig';
import { MANUAL_MASTER_DATA } from './types';
import { MASTER_DATA_DROPDOWN } from './PathConstants';

export const masterDataManual = () => async (dispatch: any) => {
  let url = appConfig.baseUrl + MASTER_DATA_DROPDOWN;

  const options = {
    url,
    method: 'GET',
    dispatch: null,
  };
  let data = await callAPI(options);
  if (data) {
    dispatch({
      type: MANUAL_MASTER_DATA,
      payload: data,
    });
  }
};
// export const resetFileUpload = () => (dispatch: any) => {
//   dispatch({
//     type: 'RESET_ERRORS',
//   });
//   dispatch({ type: FILE_UPLOAD, payload: null });
//   dispatch({ type: FILE_UPLOAD_SUCESSFULLY, payload: null });
// };
