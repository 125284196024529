import { callAPI } from 'Action';
import { MANUAL_SUPPLIER_VALIDATE, MANUAL_SUPPLIER_DATA } from './types';
import { appConfig } from 'appConfig';
import { MASTER_SUPPLIER_VALIDATE } from 'Action/PathConstants';

export const manualValidate = (value: any, actionType: any) => async (dispatch: any) => {
  let url = appConfig.baseUrl + MASTER_SUPPLIER_VALIDATE;
  let reqObj = value;

  const options = {
    url,
    actionType,
    method: 'POST',
    reqObj,
    dispatch,
  };
  let data = await callAPI(options);
  if (data) {
    dispatch({
      type: MANUAL_SUPPLIER_VALIDATE,
      payload: data,
    });
  }
  return data;
};
// export const resetFileUpload = () => (dispatch: any) => {
//   dispatch({
//     type: 'RESET_ERRORS',
//   });
//   dispatch({ type: FILE_UPLOAD, payload: null });
//   dispatch({ type: FILE_UPLOAD_SUCESSFULLY, payload: null });
// };
