import { appConfig } from 'appConfig';
import { PENDING_APPROVAL_LIST, RISK_DETAILS } from './types';
import { callAPI } from 'Action';
import { downloadExeclFile, downloadPdfFile } from 'utils/common';

export const getPendingApprovalList = () => async (dispatch) => {
  let url = appConfig.baseUrl + 'ddsinfo';
  const options = {
    url,
    method: 'GET',
    dispatch: null,
  };
  let pendingList = await callAPI(options);
  if (pendingList) {
    dispatch({
      type: PENDING_APPROVAL_LIST,
      payload: pendingList.data,
    });
  }
};

export const getRiskDetails = (dds_id: any) => async (dispatch) => {
  let url = `${appConfig.baseUrl}riskdetail/${dds_id}`;
  const options = {
    url,
    method: 'GET',
    dispatch: null,
  };
  let riskList = await callAPI(options);
  if (riskList) {
    dispatch({
      type: RISK_DETAILS,
      payload: riskList.data,
    });
  }
};

export const downloadDDSPdf = (dds_id: any) => async (dispatch) => {
  let url = `${appConfig.baseUrl}FEDSAPDFDetails/${dds_id}`;
  const options = {
    url,
    method: 'GET',
    dispatch: null,
  };
  let pdfData = await callAPI(options);
  if (pdfData) {
    let byte = pdfData.data.fileBytes;
    if (byte) {
      downloadPdfFile(byte, pdfData);
    }
  }
};

export const downloadDDSExcel = (dds_id: any) => async (dispatch) => {
  let url = `${appConfig.baseUrl}GLAResult/${dds_id}`;
  const options = {
    url,
    method: 'GET',
    dispatch: null,
  };
  let excelData = await callAPI(options);
  if (excelData) {
    let byte = excelData.data.fileBytes;
    if (byte) {
      downloadExeclFile(byte, excelData);
    }
  }
};
