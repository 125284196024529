import { MANUAL_SUBMIT } from 'Action/types';

const initialState = {
  manualEntryData: null,
};

export default (state: any, action: any) => {
  if (!state) {
    state = initialState;
  }

  switch (action.type) {
    case MANUAL_SUBMIT:
      return {
        ...state,
        manualEntryData: action.payload,
      };

    // case FILE_UPLOAD_SUCESSFULLY:
    //   return {
    //     ...state,
    //     fileUploadStatus: action.payload,
    //   };
    default:
      return state;
  }
};
