import { PENDING_APPROVAL_LIST, RISK_DETAILS } from 'Action/types';

const initialState = {
  pendingApprovalList: null,
};

export default (state: any, action: any) => {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case PENDING_APPROVAL_LIST:
      return {
        ...state,
        pendingApprovalList: action.payload,
      };
    case RISK_DETAILS:
      return {
        ...state,
        riskDetailList: action.payload,
      };
    default:
      return state;
  }
};
