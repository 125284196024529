const FILE_UPLOAD = 'FILE_UPLOAD';
const FILE_UPLOAD_SUCESSFULLY = 'FILE_UPLOAD_SUCESSFULLY';
const PENDING_APPROVAL_LIST = 'PENDING_APPROVAL_LIST';
const MANUAL_SUBMIT = 'MANUAL_SUBMIT';
const MANUAL_SUCCESS = 'MANUAL_SUCCESS';
const MANUAL_MASTER_DATA = 'MANUAL_MASTER_DATA';
const MANUAL_SUPPLIER_DATA = 'MANUAL_SUPPLIER_DATA';
const MANUAL_SUPPLIER_VALIDATE = 'MANUAL_SUPPLIER_VALIDATE';
const MANUAL_SUPPLIER_FETCH = 'MANUAL_SUPPLIER_FETCH';
const RISK_DETAILS = 'RISK_DETAILS';
export {
  FILE_UPLOAD,
  FILE_UPLOAD_SUCESSFULLY,
  MANUAL_SUCCESS,
  MANUAL_SUBMIT,
  MANUAL_MASTER_DATA,
  PENDING_APPROVAL_LIST,
  MANUAL_SUPPLIER_VALIDATE,
  MANUAL_SUPPLIER_DATA,
  MANUAL_SUPPLIER_FETCH,
  RISK_DETAILS,
};
