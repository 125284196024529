const initialState = {
  errorFlag: false,
  errorType: null,
  errorMessage: null,
  error: null,
  errorCode: null,
};

export default function (state, action) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case 'GET_ERRORS': {
      return {
        errorFlag: true,
        errorType: 'GENERIC_ERROR',
        errorMessage: action.payload.errorMessage || action.payload.error.message,
        errorCode: action.payload.error?.response?.status,
        error: action.payload.error,
      };
    }

    case 'RESET_ERRORS': {
      return {
        errorFlag: false,
        errorType: null,
        errorMessage: null,
      };
    }
    case 'MANUAL_ERROR': {
      return {
        errorFlag: true,
        errorType: 'manualerror',
        errorMessage: 'DDS Submission failed',
      };
    }
    default:
      return state;
  }
}
