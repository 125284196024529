import {
  MANUAL_SUPPLIER_DATA,
  MANUAL_SUPPLIER_VALIDATE,
  MANUAL_SUPPLIER_FETCH,
} from 'Action/types';

const initialState = {
  manualSupplierData: null,
  manualValidateStatus: null,
  manualFetchData: null,
};

export default (state: any, action: any) => {
  if (!state) {
    state = initialState;
  }

  switch (action.type) {
    case MANUAL_SUPPLIER_DATA:
      return {
        ...state,
        manualSupplierData: action.payload,
      };

    case MANUAL_SUPPLIER_VALIDATE:
      return {
        ...state,
        manualValidateStatus: action.payload,
      };
    case MANUAL_SUPPLIER_FETCH:
      return {
        ...state,
        manualFetchData: action.payload,
      };
    default:
      return state;
  }
};
