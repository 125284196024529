import Combobox, { MenuItem } from '@ingka/combobox';
import React, { useState, useEffect } from 'react';
import InputField from '@ingka/input-field';
import FormField from '@ingka/form-field';
import styled from 'styled-components';
import { contentDelimiterStyles } from 'components/layout';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import RadioButtonGroup from '@ingka/radio-button-group';
import Products from 'pages/Products/products';
import Commodities from 'pages/Commodities/commodities';
import Button from '@ingka/button';
import { Select, Option } from '@ingka/select';
import { manualSubmit, resetManualEntry } from 'Action/manualSubmitAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
import Toast from '@ingka/toast';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/forms/dist/style.css';
import { useNavigate } from 'react-router-dom';
import { manualValidate } from 'Action/manualValidateAction';
import Accordion, { AccordionItem } from '@ingka/accordion';
import AssociatedDds from 'pages/AssociatedDDS/associatedDds';
import { masterDataManual } from 'Action/manualDropDownAction';
import { MANUAL_SUPPLIER_VALIDATE } from 'Action/types';

const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  justify-content: space-between;
`;
const SecondDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  justify-content: space-between;
`;

// const tabs = [<Tab key="tab-3" text={'Products'} tabPanelId="tab_3" />];
// const tabPanels = [
//   ,
//   <TabPanel key="tab-3" tabPanelId="tab_3" title="Product Name[GTIN]" />,
//   <TabPanel key="tab-4" tabPanelId="tab_4" title="Commodities" />,
// ];

const ManualEntry = (props: any) => {
  const { masterData } = useSelector((state: RootState) => state.dropDownMasterData);
  const { manualFetchData } = useSelector((state: RootState) => state.manualValidateDetails);
  const [toastOpen, setToastOpen] = useState<any>(false);
  const [isValidate, setIsValidate] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [supplierRows, setSupplierRows] = useState([
    { supplierId: '', ddsReferenceNumber: '', ddsVerificationNumber: '', ddsFilingDate: '' },
  ]);
  const [productRows, setproductRows] = useState([
    { productName: '', productIdType: '', productId: '' },
  ]);
  const [oldDdsRows, setOldDdsRows] = useState([
    { DdsFirstProduction: '', verificationNumber: '' },
  ]);
  const [commodityRows, setCommodityRows] = useState([
    {
      hsHeader: null,
      descriptionOfGoods: '',
      netWeight: null,
      numberofUnits: null,
      volume: null,
      universalUnit: '',
    },
  ]);
  console.log('manualFetchData', manualFetchData);

  // setCommodityRows([...commodityRows,manualFetchData?.data.statementInfo?.commodities[0]])
  const [productionPlaceRows, setProductionPlaceRows] = useState([
    { productionPlace: '', type: '', coordinates: null, area: null },
  ]);
  const [producerRows, setProducerRows] = useState([{ producerName: '', producerCountry: '' }]);
  const [selectedSupplier, setselectedSupplier] = useState<string>('');
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [shouldValidate, setShouldValidate] = useState(false);
  const [validateVerification, setValidateVerification] = useState(false);
  const [isFilingImport, setIsFilingImport] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const { manualEntryData } = useSelector((state: RootState) => state.manualEntrySubmitData);
  const { error, errorMessage } = useSelector((state: RootState) => state.error);

  const navigate = useNavigate();

  const allFieldsFilled = () => {
    return (
      supplierRows.every((row) => Object.values(row).every((value) => value !== '')) &&
      productRows.every((row) => Object.values(row).every((value) => value !== '')) &&
      commodityRows.every((row) =>
        Object.values(row).every((value) => value !== null && value !== '')
      ) &&
      productionPlaceRows.every((row) =>
        Object.values(row).every((value) => value !== null && value !== '')
      ) &&
      producerRows.every((row) => Object.values(row).every((value) => value !== '')) &&
      selectedSupplier !== ''
    );
  };

  useEffect(() => {
    if (manualFetchData) {
      const transformData = ({
        data: {
          statementInfo: { commodities },
        },
      }) => {
        return commodities.map(
          ({
            hsHeading,
            descriptionOfGoods,
            goodsMeasure: { volume, netWeight, numberofUnits, universalUnit },
          }) => ({
            hsHeader: hsHeading || null,
            descriptionOfGoods: descriptionOfGoods || '',
            netWeight: netWeight || null,
            numberofUnits: numberofUnits || null,
            volume: volume || null,
            universalUnit: universalUnit || '',
          })
        );
      };

      const transformProducerData = ({
        data: {
          statementInfo: {
            commodities: [{ producers }],
          },
        },
      }) => {
        console.log(producers, 'producers');
        if (producers && Array.isArray(producers)) {
          return producers.map(({ producerCountry, producerName }) => ({
            producerCountry: producerCountry || null,
            producerName: producerName || null,
          }));
        }
      };
      const transformProductionPlaceData = ({
        data: {
          statementInfo: {
            commodities: [{ producers }],
          },
        },
      }) => {
        return producers.map(
          ({
            geometryGeoJson: {
              features: [
                {
                  properties: { productionPlace, area },
                  geometry: { type, coordinatesString },
                },
              ],
            },
          }) => ({
            productionPlace: productionPlace || null,
            area: area || null,
            type: type || null,
            coordinatesString: coordinatesString || null,
          })
        );
      };

      const transformedData = transformData(manualFetchData);
      const transformedProducer = transformProducerData(manualFetchData);
      const transferproductionPlace = transformProductionPlaceData(manualFetchData);
      setCommodityRows([...transformedData]);
      setIsAccordionOpen(true);
      setProducerRows([...transformedProducer]);
      setProductionPlaceRows([...transferproductionPlace]);
      console.log(transformedData);
      console.log(transferproductionPlace, 'transferproductionPlace');
      console.log(producerRows, 'commodityrow');
    }
  }, [manualFetchData]);

  useEffect(() => {
    setToastOpen(false);

    if (manualEntryData) {
      setToastOpen(true);
      console.log(manualEntryData, 'errorMessage success');
      dispatch({
        type: 'MANUAL_SUCCESS',
        payload: null,
      });

      return;
    }
    if (errorMessage) {
      setToastOpen(true);
      console.log(errorMessage, 'errorMessage');
      dispatch({
        type: 'MANUAL_ERROR',
        payload: null,
      });
    }
  }, [manualEntryData, errorMessage]);

  useEffect(() => {
    if (toastOpen && manualEntryData) {
      const timer = setTimeout(() => {
        dispatch({
          type: 'MANUAL_SUBMIT',
          payload: null,
        });

        navigate('/approval');
        setToastOpen(false);
      }, 3000); // Delay of 3 seconds

      return () => clearTimeout(timer);
    }
  }, [toastOpen, manualEntryData]);
  useEffect(() => {
    if (toastOpen && errorMessage) {
      dispatch({
        type: 'MANUAL_ERROR',
        payload: null,
      });
    }
  }, [toastOpen, errorMessage]);

  useEffect(() => {
    dispatch(masterDataManual());
  }, []);

  const payload = {
    supplierId: supplierRows[0].supplierId,
    ddsReferenceNumber: supplierRows[0].ddsReferenceNumber,
    ddsVerificationNumber: supplierRows[0].ddsVerificationNumber,
    // ddsFilingDate: supplierRows[0].ddsFilingDate,
    ddsFilingDate: '0001-01-01T00:00:00',
    isSupplierDDS: true,
    associatedProducts: [
      {
        productId: productRows[0].productId,
        productIdType: productRows[0].productIdType,
        productName: productRows[0].productName,
      },
    ],
    commodities: [
      {
        hsHeading: commodityRows[0]?.hsHeader,
        descriptionOfGoods: commodityRows[0]?.descriptionOfGoods,
        goodsMeasure: {
          volume: parseFloat(commodityRows[0]?.volume),
          netWeight: parseFloat(commodityRows[0]?.netWeight),
          numberofUnits: parseFloat(commodityRows[0]?.numberofUnits),
          universalUnit: commodityRows[0]?.universalUnit,
        },
        producers: [
          {
            producerName: producerRows[0]?.producerName,
            producerCountry: producerRows[0]?.producerCountry,
            geometryGeoJson: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: {
                    productionPlace: productionPlaceRows[0].productionPlace,
                    area: parseFloat(productionPlaceRows[0]?.area),
                  },
                  geometry: {
                    type: productionPlaceRows[0]?.type,
                    coordinates: [productionPlaceRows[0].coordinates],
                  },
                },
              ],
            },
          },
        ],
      },
    ],
  };

  const handleValidate = async () => {
    try {
      const response = await dispatch(manualValidate(payload, MANUAL_SUPPLIER_VALIDATE));

      // console.log((response.data.statusCode),'response')
      if (response && response?.status === 200) {
        console.log(response.data.statusCode, 'response');
        setIsValidate(true);

        setValidationMessage('Validated successfully');
      }
    } catch (error) {
      console.error('Validation failed:', error);
      setValidationMessage('Validation failed');
    }
  };

  const handleManualSubmit = () => {
    setToastOpen(false);

    dispatch(manualSubmit(payload));
  };
  const onClose = () => {
    setToastOpen(false);
  };
  console.log(masterData, 'masterdata');
  console.log(masterData && masterData[3], 'masterdatanew');
  const handleSupplierChange = (index: number, field: any, value: any, event: any) => {
    const newSupplierRows = supplierRows.map((row: any, i: any) =>
      i === index ? { ...row, [field]: value } : row
    );
    if (field === 'ddsReferenceNumber' && value.length > 14) {
      setShouldValidate(true);
    } else {
      setShouldValidate(false);
    }
    if (field === 'ddsVerificationNumber' && value.length > 14) {
      setValidateVerification(true);
    } else {
      setValidateVerification(false);
    }
    console.log(supplierRows, 'supplierRows');
    props.supplierDetails(...newSupplierRows);
    setSupplierRows(newSupplierRows);
    setselectedSupplier(event.target.value);
  };

  const handleFilingImport = () => {
    setIsFilingImport(true);
  };

  return (
    <div>
      <FormField>
        {supplierRows.map((row: any, index: number) => (
          <>
            <div className="supplier-tab" key={index} style={{ display: 'flex', gap: 20 }}>
              <Select
                id="supplierId"
                label="Supplier name"
                value={row.supplierId}
                onChange={(e: any) => handleSupplierChange(index, 'supplierId', e.target.value, e)}
                style={{ width: 'auto' }}
              >
                {masterData?.supplierMaster &&
                  masterData?.supplierMaster.map((data: any) => (
                    <Option
                      key={data.supplierId}
                      value={data.supplierId}
                      name={data.supplierName}
                    />
                  ))}
              </Select>
              <FormField
                characterLimit={14}
                fieldHelper={{
                  id: 'helper-msg-id',
                  msg: 'Enter DDS Ref number',

                  type: '',
                }}
                shouldValidate={shouldValidate}
                valid={
                  row.ddsReferenceNumber &&
                  (row.ddsReferenceNumber.length >= 14 || !/^\d+$/.test(row.ddsReferenceNumber))
                    ? 'error'
                    : false
                }
                validation={{
                  id: 'error-msg-id',
                  msg: 'Enter valid Reference number',
                  type: 'error',
                }}
              >
                <InputField
                  label="DDS reference number"
                  iconPosition="leading"
                  id={`dds-ref-${index}`}
                  type="number"
                  style={{ marginRight: 10 }}
                  value={row.ddsReferenceNumber}
                  onChange={(e) =>
                    handleSupplierChange(index, 'ddsReferenceNumber', e.target.value, e)
                  }
                />
              </FormField>
              <FormField
                characterLimit={13}
                fieldHelper={{
                  id: 'helper-id',
                  msg: 'Enter DDS Verification number',
                  type: '',
                }}
                shouldValidate={validateVerification}
                valid={
                  row.ddsVerificationNumber &&
                  (row.ddsVerificationNumber.length >= 13 ||
                    !/^\d+$/.test(row.ddsVerificationNumber))
                    ? 'error'
                    : false
                }
                validation={{
                  id: 'error-msg-id',
                  msg: 'dds number should be 13 digits',
                  type: 'error',
                }}
              >
                <InputField
                  style={{ marginRight: 10 }}
                  label={
                    index === 0 ? (
                      <>
                        DDs verification number
                        {row.ddsReferenceNumber && <span className="red-asterisk"> *</span>}
                      </>
                    ) : (
                      ''
                    )
                  }
                  required={!!row.ddsReferenceNumber}
                  iconPosition="leading"
                  id={`dds-ver-${index}`}
                  type="number"
                  value={row.ddsVerificationNumber}
                  onChange={(e) =>
                    handleSupplierChange(index, 'ddsVerificationNumber', e.target.value, e)
                  }
                />
              </FormField>

              <Button
                iconPosition="leading"
                key="validate"
                size="small"
                text="Validate"
                onClick={handleValidate}
                // disabled={!allFieldsFilled()}
              ></Button>
              {validationMessage && <div>{validationMessage}</div>}
            </div>
            <span>OR</span>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
              <Button size="small" onClick={handleFilingImport}>
                Filing for import
              </Button>
            </div>
          </>
        ))}
        {(isValidate || isFilingImport) && (
          <>
            <Products setproductRows={setproductRows} productRows={productRows} />
            <AssociatedDds oldDdsRows={oldDdsRows} setOldDdsRows={setOldDdsRows} />
            <div>
              <Commodities
                commodityRows={commodityRows}
                setCommodityRows={setCommodityRows}
                productionPlaceRows={productionPlaceRows}
                setProductionPlaceRows={setProductionPlaceRows}
                producerRows={producerRows}
                setProducerRows={setProducerRows}
                isAccordionOpen={isAccordionOpen}
              />
            </div>
          </>
        )}
      </FormField>

      <Button
        iconPosition="leading"
        key="submit"
        size="small"
        text="Submit"
        type="emphasised"
        onClick={handleManualSubmit}
        // disabled={!allFieldsFilled()}
      ></Button>
      {manualEntryData && (
        <Toast
          text={
            <>
              <strong>{'DDS sent successfully'}</strong>
            </>
          }
          isOpen={toastOpen}
          actionClick={() => {}} // required if there is an action label supplied
          onCloseRequest={onClose}
          ariaLabelCloseBtn="Dismiss notification"
        />
      )}
    </div>
  );
};
export default ManualEntry;
