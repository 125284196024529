import React from 'react';
import AspectRatioBox from '@ingka/aspect-ratio-box';
import Checkbox from '@ingka/checkbox';
import TextArea from '@ingka/text-area';
import Combobox, { MenuItem } from '@ingka/combobox';
import Button from '@ingka/button';
import TableLayout from '../TableLayout/tableLayout';
import './detailsLayout.scss';
const DetailsLayout = (props: any) => {
  const {
    data,
    buttonData,
    isCheckBoxDisable,
    isTextAreaDisbale,
    ddsReference,
    pendingApprovalData,
  } = props;
  return (
    <div>
      <div className="display-details">
        <div>
          <span>DDS Reference Number : {ddsReference || '1234567890'}</span>
        </div>
        <div>
          <span>DSS Verification Number : 9808</span>
        </div>
        <TableLayout
          items={data}
          isLeftSide={false}
          ddsReference={ddsReference}
          pendingApprovalData={pendingApprovalData}
        />
      </div>

      <div style={{ marginTop: '10px' }}>
        <Checkbox
          id="example-id"
          label="Auto Approve for all the products in DDS"
          name="example-group"
          value="checkbox 1"
          disabled={isCheckBoxDisable}
        />
      </div>
      <div style={{ marginTop: '10px' }}>
        <Checkbox
          id="example-id"
          label="I have reviewed the risk and agree to proceed with submission"
          name="example-group"
          value="checkbox 1"
          disabled={isCheckBoxDisable}
        />
      </div>
      <div style={{ marginTop: '10px' }}>
        <TextArea id="example-id" label="Notes" disabled={isTextAreaDisbale} />
      </div>
      <div>
        <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
          {buttonData.map((button, index) => (
            <Button
              key={index}
              className="demo-pill--stacking-story"
              iconPosition="leading"
              text={button.label}
              onClick={(e) => button.onClick(e, button, index)}
              size="small"
              type="emphasised"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetailsLayout;
