import React, { useState } from 'react';
import Table, { TableBody, TableHeader } from '@ingka/table';
import Pill from '@ingka/pill';
import document from '@ingka/ssr-icon/paths/document';
import './tableLayout.scss';
import Status from '@ingka/status';
import { getFEDSAReferenceNumber, statusCheck } from 'utils/common';
import Search from '@ingka/search';
import Tooltip from '@ingka/tooltip';
import { useDispatch } from 'react-redux';
import { downloadDDSExcel, downloadDDSPdf } from 'Action/pendingApprovalAction';
import { AppDispatch } from 'Store/store';
const TableLayout = (props: any) => {
  const { items, isLeftSide, pendingApprovalData, ddsReference } = props;
  const headers = Object.keys(items[0]);
  const capitalizeHeader = (header) =>
    header
      .replace(/_/g, ' ')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  const MAX_ARTICLES_LENGTH = 20;
  const [highlightedRow, setHighlightedRow] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const downloadPdf = (e: any, header: any, item: any, type: any) => {
    e.stopPropagation();
    let id = getFEDSAReferenceNumber(pendingApprovalData, ddsReference || item.DDS_Ref_Num);
    if (type === 'pdf') {
      dispatch(downloadDDSPdf(id));
    } else {
      dispatch(downloadDDSExcel(id));
    }
  };

  const status = (products: any) => {
    let stockAvailablity = statusCheck(products);
    return (
      <Status
        statusColor={stockAvailablity.color as any}
        statusDotPosition="leading"
        small={false}
      />
    );
  };
  const renderTableDetails = (header, item) => {
    if (header === 'PDF') {
      return (
        <Pill
          size="xsmall"
          onClick={(e) => downloadPdf(e, header, item, 'pdf')}
          ssrIcon={document}
        />
      );
    } else if (header === 'CRA') {
      return (
        <div className="tooltip-demo__container">
          <Tooltip
            describedById="tooltip-example"
            position="top"
            tooltipText="Required Data needs to be added"
          >
            {status(item)}
          </Tooltip>
        </div>
      );
    } else if (header === 'GLA') {
      return (
        <span
          style={{ color: item?.CRA.isPassed === false ? 'red' : 'green', cursor: 'pointer' }}
          onClick={(e) => downloadPdf(e, header, item, 'excel')}
        >
          {item[header].passedCount}
        </span>
      );
    } else if (header === 'articles') {
      // Check if articles text is too long
      const articlesText = item[header];
      const showTooltip = articlesText.length > MAX_ARTICLES_LENGTH;
      const displayText = showTooltip
        ? `${articlesText.slice(0, MAX_ARTICLES_LENGTH)}...`
        : articlesText;
      return showTooltip ? (
        <Tooltip describedById="tooltip-example" position="top" tooltipText={articlesText}>
          <span>{displayText}</span> {/* Wrap with a span if necessary */}
        </Tooltip>
      ) : (
        displayText
      );

      // return displayText;
    } else if (header === 'commodities') {
      // Check if articles text is too long
      const articlesText = item[header];
      const showTooltip = articlesText.length > MAX_ARTICLES_LENGTH;
      const displayText = showTooltip
        ? `${articlesText.slice(0, MAX_ARTICLES_LENGTH)}...`
        : articlesText;
      return showTooltip ? (
        <Tooltip describedById="tooltip-example" position="top" tooltipText={articlesText}>
          <span>{displayText}</span> {/* Wrap with a span if necessary */}
        </Tooltip>
      ) : (
        displayText
      );
    } else if (header === 'Source') {
      return item[header] === false ? 'Manual' : 'API';
    } else {
      return item[header];
    }
  };
  const searchfunc = (e: any) => {
    console.log(e.target.value);
    props?.onSearch(e);
  };
  const onTableClick = (item: any, index: any) => {
    if (isLeftSide) {
      if (highlightedRow === index) {
        setHighlightedRow('');
        props?.onShowDetails(false);
      } else {
        setHighlightedRow(index);
        props?.onShowDetails(true, item.DDS_Ref_Num);
      }
    }
  };
  return (
    <div>
      <div style={{ width: '40%' }}>
        {isLeftSide && (
          <Search
            ariaLabel="Search for product categories, product names, etc."
            clearBtnText="Clear search field"
            id="search"
            onChange={(e) => {
              searchfunc(e);
            }}
            placeholder="Search"
            // scope={{
            //   All: 'All',
            //   Deals: 'Deals',
            // }}

            submitBtnText="Submit"
          />
        )}
      </div>
      <div style={{ marginTop: '20px' }}>
        <Table className="table">
          <TableHeader sticky>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{capitalizeHeader(header)}</th>
              ))}
            </tr>
          </TableHeader>

          <TableBody striped>
            {items.map((item, index) => (
              <tr
                key={index}
                onClick={() => onTableClick(item, index)}
                className={highlightedRow === index ? 'highlighted-row' : ''}
              >
                {headers.map((header, idx) => (
                  <td key={idx}>{renderTableDetails(header, item)}</td>
                ))}
              </tr>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default TableLayout;
