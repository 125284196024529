import { configuredAxios } from 'api/configuredAxios';
export const callAPI = (options: any) => {
  const { url, reqObj, dispatch, method, actionType, errorType, ERROR_TYPE, errorCallbackType } =
    options;
  const apiURL = url;

  return configuredAxios({
    url: apiURL,
    method: method || apiMethods.GET,
    data: reqObj,
  })
    .then((response) => {
      dispatch &&
        dispatch({
          type: actionType,
          payload: response.data,
        });
      return response;
    })
    .catch((error) => {
      if (options?.errorCallback) {
        options.errorCallback(reqObj, error, errorType || ERROR_TYPE, dispatch, errorCallbackType);
      } else {
        handleError(error, dispatch, 'GET_ERRORS');
      }
    });
};

export const handleError = (error: any, dispatch: any, errorType: any) => {
  let errorData: any;
  if (error && error.response && error.response.data?.message && error.response.status !== 400) {
    errorData = error.response.data.message;
  } else if (error?.response?.status === 400) {
    errorData = error?.response?.data?.message;
  } else {
    errorData = error?.response?.data?.message;
  }
  dispatch && dispatch(getErrorObject(errorType, errorData, error));
};

export const getErrorObject = (type: any, response: any, error: any) => {
  return {
    type,
    payload: {
      errorMessage: response,
      error: error,
    },
  };
};

export const apiMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'PUT',
  PATCH: 'PATCH',
};
