/* eslint-disable prettier/prettier */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from '@azure/msal-react';
import Button from '@ingka/button';
import { loginRequest } from 'components/ProtectedBackendPanel/authProvider';
import styled from 'styled-components';
import { contentDelimiterStyles } from 'components/layout';
import skapaToken from '@ingka/variables';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import Text from '@ingka/text';
import { appConfig } from 'appConfig';

const AppHeaderContainer = styled.header`
  background-color: ${skapaToken.colourNeutralWhite};
  box-shadow: 0px 4px 15px -10px ${skapaToken.colourNeutralGrey500};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const AppHeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${contentDelimiterStyles};
`;

const AppHeaderRows = styled.div`
  justify-content: space-between;
  display: flex;
`;
const AppHeaderButton = styled.div`
  display: flex;
    align-items: center;
    padding: 0.5rem;
    justify-content: space-between;
    gap: 1rem;
    margin; 12px
`;
const VersionHeaderRow = styled(AppHeaderRow)`
  padding-top: 0;
  padding-bottom: 0;
`;
const MenuLink = styled(Link)<{ $isDisabled?: boolean }>`
  color: ${skapaToken.colourNeutralGrey700};
  text-decoration: inherit;
  font-size: small;
  font-height: 1.2;
  font-weight: 400;
  text-underline-offset: 2px;
  ${(props) =>
    props.$isDisabled &&
    `
    cursor: not-allowed;
    pointer-events: none;
    color: ${skapaToken.colourNeutralGrey200};
  `}
`;

const LeftMenuLink = styled(MenuLink)`
  margin-right: 16px;
`;

const Logo = styled.div`
  background-image: url('https://www.ikea.com/se/sv/static/ikea-logo.f7d9229f806b59ec64cb.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 75px;
  height: 30px;
`;

const AppHeader = () => {
  const { instance, accounts } = useMsal();
  const userAccountInfo = useAccount(accounts[0] || {});
  const appInsights = useAppInsightsContext();
  const trackSignedOut = useTrackEvent(appInsights, 'Customer Signed Out', {});

  const signOut = () =>
    instance
      .logoutPopup()
      .then(() => trackSignedOut({}))
      .then(() => window.location.reload());
  const navigate = useNavigate();

  const login = () => {
    navigate('/');
    instance.loginRedirect(loginRequest);
  };

  return (
    <AppHeaderContainer>
      <AppHeaderRows>
        <AppHeaderRow>
          <MenuLink to="/about">About</MenuLink>
        </AppHeaderRow>
        <AppHeaderRow>
          <LeftMenuLink to="/approval" $isDisabled={!userAccountInfo}>
            Fedsa
          </LeftMenuLink>
        </AppHeaderRow>
        {/* <AuthenticatedTemplate>
          <AppHeaderRow>
            <MenuLink to="/fedsa">Fedsa</MenuLink>
          </AppHeaderRow>
        </AuthenticatedTemplate> */}
        {/* <VersionHeaderRow> */}
        {/* <Text>{appConfig.version}</Text>
          <Text>{userAccountInfo?.name}</Text>
        </VersionHeaderRow> */}
      </AppHeaderRows>
      <AppHeaderButton>
        <Link to="/">
          <Logo />
        </Link>
        <AuthenticatedTemplate>
          <Button size="small" type="secondary" onClick={signOut}>
            Sign out
          </Button>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Button size="small" type="primary" onClick={login}>
            Login
          </Button>
        </UnauthenticatedTemplate>
      </AppHeaderButton>
    </AppHeaderContainer>
  );
};

export default AppHeader;
