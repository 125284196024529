import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'index.css';
import App from 'components/App';
import reportWebVitals from 'reportWebVitals';
import { msalInstance } from 'utils/msalInstance';
import 'appInsights';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'Store/store';

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <App pca={msalInstance} />
      </Router>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
