import Text from '@ingka/text';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import { Stack, Page } from 'components/layout';
import React, { ChangeEvent, useRef, useState, useEffect } from 'react';
import FormField from '@ingka/form-field';
import ManualEntry from 'pages/ManualEntry/manualEntry';
import Button from '@ingka/button';
import styled from 'styled-components';
import { contentDelimiterStyles } from 'components/layout';
import axios from 'axios';
import InputField from '@ingka/input-field';
import { DetailPage } from 'pages/Detail/Detail';
import { useLocation, useNavigate } from 'react-router-dom';
import SSRIcon from '@ingka/ssr-icon';
import documentAdd from '@ingka/ssr-icon/paths/document-add';
import Pill from '@ingka/pill';
import { convertFileToBase64 } from 'utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
import { fileUpload, resetFileUpload } from 'Action/fileUploadAction';
import Toast from '@ingka/toast';

const FileUploadTemplate = styled.div`
  display: flex;
  flex-direction: column;
  width: min-content;
`;

export const Fedsa = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filePath, setFilePath] = useState<string>('');
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [byteStreamData, setByteStreamData] = useState<any>();
  const [toastOpen, setToastOpen] = useState<any>(false);
  const dispatch = useDispatch<AppDispatch>();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { fileUploadData, fileUploadStatus } = useSelector(
    (state: RootState) => state.fileUploadDetails
  );
  const { error, errorMessage } = useSelector((state: RootState) => state.error);

  // const [status, setStatus] = useState<boolean>(false);
  useEffect(() => {
    setToastOpen(false);
    dispatch({
      type: 'FILE_UPLOAD',
      payload: null,
    });
    dispatch({
      type: 'FILE_UPLOAD_SUCESSFULLY',
      payload: null,
    });
    dispatch({
      type: 'RESET_ERROR',
      payload: null,
    });
  }, [window.location.href]);
  useEffect(() => {
    setToastOpen(false);
    if (fileUploadData || errorMessage) {
      setToastOpen(true);

      if (errorMessage) {
        dispatch({
          type: 'FILE_UPLOAD',
          payload: null,
        });
        dispatch({
          type: 'FILE_UPLOAD_SUCESSFULLY',
          payload: null,
        });
        dispatch({
          type: 'RESET_ERROR',
          payload: null,
        });
      }
    }
  }, [fileUploadData, errorMessage]);
  useEffect(() => {
    if (toastOpen && fileUploadData) {
      const timer = setTimeout(() => {
        navigate('/approval');
        setToastOpen(false);
      }, 3000); // Delay of 3 seconds

      return () => clearTimeout(timer);
    }
  }, [toastOpen, fileUploadData]);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(resetFileUpload());
    if (event.target.files) {
      const file = event.target.files[0];
      const fileExtension = file?.name.slice(file.name.lastIndexOf('.') + 1) || '';
      setSelectedFile(event.target.files[0]);
      const byteStream = await convertFileToBase64(file);
      setByteStreamData(byteStream);
      setByteStreamData({
        fileBytes: byteStream,
        fileType: fileExtension,
      });
      // event.target.value = null;
    }
  };

  // const handleDownload = () => {
  //   if (selectedFile) {
  //     const url = URL.createObjectURL(selectedFile.file[0]);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.download = selectedFile.name;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     URL.revokeObjectURL(url);
  //   }
  // };
  const handleCsvTemplate = () => {
    const filePath = '/Countries_sample_data.csv';
    const a = document.createElement('a');
    a.href = filePath;
    a.download = 'Countries_sample_data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const handleJsonTemplate = () => {
    const filePath = '/new 1.json';
    const a = document.createElement('a');
    a.href = filePath;
    a.download = 'new 1.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const navigate = useNavigate();
  const location = useLocation();
  // const handlePathChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   setFilePath(event.target.value);
  //   const selected = event.target.files?.[0];
  //   if (selected) {
  //     setSelectedFile(selected);
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const text = e.target?.result as string;
  //       setFilePath(text);
  //     };
  //   }
  // };

  const handleSubmit = () => {
    setToastOpen(false);
    dispatch(fileUpload(byteStreamData));
    if (errorMessage) {
      dispatch({
        type: 'FILE_UPLOAD',
        payload: null,
      });
      dispatch({
        type: 'FILE_UPLOAD_SUCESSFULLY',
        payload: null,
      });
      dispatch({
        type: 'RESET_ERROR',
        payload: null,
      });
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setSelectedFile(null);
    }
    // setStatus(true);
    // navigate('/detail');
  };
  const onClose = () => {
    setToastOpen(false);
  };
  const supplierDetails = (value: any) => {
    console.log(value, 'supplierDetails');
  };
  // const handleUpload = async () => {
  //   if (!selectedFile) return;

  //   const formData = new FormData();
  //   formData.append('file', selectedFile);

  //   try {
  //     const response = await axios.post('/upload', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //       onUploadProgress: (progressEvent) => {
  //         const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //         setUploadProgress(progress);
  //       },
  //     });

  //     console.log('File uploaded successfully:', response.data);
  //   } catch (error) {
  //     console.error('Error uploading file:', error);
  //   }
  // };

  const tabs = [
    <Tab key="tab-1" text={'File Upload'} tabPanelId="tab_1" />,
    <Tab key="tab-2" text={'Manual Entry'} tabPanelId="tab_2" />,
  ];

  const tabPanels = [
    <TabPanel key="tab-1" tabPanelId="tab_1">
      <FormField>
        <input type="file" onChange={handleFileChange} accept=".json,.csv" ref={fileInputRef} />

        {uploadProgress > 0 && <p>Upload Progress: {uploadProgress}%</p>}
      </FormField>
      <FileUploadTemplate>
        <Pill
          iconPosition="trailing"
          size="small"
          label="Json file template"
          onClick={handleJsonTemplate}
          ssrIcon={documentAdd}
          style={{ marginBottom: '10px', borderRadius: '0px' }}
        />
        <Pill
          iconPosition="trailing"
          size="small"
          label="csv file template"
          onClick={handleCsvTemplate}
          ssrIcon={documentAdd}
          style={{ marginBottom: '10px', borderRadius: '0px' }}
        />
      </FileUploadTemplate>
      {selectedFile ? (
        <Button
          iconPosition="leading"
          key="submit"
          size="medium"
          text="Submit"
          type="emphasised"
          onClick={handleSubmit}
        ></Button>
      ) : (
        <Button
          iconPosition="leading"
          key="submit"
          size="medium"
          text="Submit"
          type="emphasised"
          disabled
        ></Button>
      )}
    </TabPanel>,
    <TabPanel key="tab-2" tabPanelId="tab_2">
      <>
        <ManualEntry supplierDetails={supplierDetails} />
      </>
    </TabPanel>,
  ];

  return (
    <Page>
      <Text headingSize="xl" tagName="h1">
        FEDSA
      </Text>
      {location.pathname === '/approval' ? (
        <DetailPage />
      ) : (
        <div>
          <Tabs
            tabs={tabs}
            tabPanels={tabPanels}
            defaultActiveTab="tab_1"
            ariaLabel="Example Tabs Label"
          />
          {/* {selectedFile ? (
            <Button
              iconPosition="leading"
              key="submit"
              size="medium"
              text="Submit"
              type="emphasised"
              onClick={handleSubmit}
            ></Button>
          ) : (
            <Button
              iconPosition="leading"
              key="submit"
              size="medium"
              text="Submit"
              type="emphasised"
              disabled
            ></Button>
          )} */}
        </div>
      )}
      {(fileUploadData || errorMessage) && (
        <Toast
          text={
            <>
              <strong>{fileUploadData || errorMessage}</strong>
            </>
          }
          isOpen={toastOpen}
          actionClick={() => {}} // required if there is an action label supplied
          onCloseRequest={onClose}
          ariaLabelCloseBtn="Dismiss notification"
        />
      )}
    </Page>
  );
};
