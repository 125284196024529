import React from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { Page } from 'components/layout';
import { Fedsa } from 'pages/Fedsa';

const Home = () => (
  <Page>
    <AuthenticatedTemplate>{/* <Fedsa /> */}</AuthenticatedTemplate>
  </Page>
);

export default Home;
