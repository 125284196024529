import React from 'react';
import './saveForLater.scss';
import TableLayout from 'components/SharedLayout/TableLayout/tableLayout';
import DetailsLayout from 'components/SharedLayout/DetailsLayout/detailsLayout';

const SaveForLater = () => {
  const items = [
    {
      source: 'Api',
      articles:
        'GTN1,GTN2,GTN6,GTN7,GTN8,GTN9,GTN10,GTN11,GTN1,GTN2,GTN6,GTN7,GTN8,GTN9,GTN10,GTN11',
      supplierName: 'Supplier 1',
      commodities: '123,2345',
      ddsNo: 1234,
      date: '2024-10-10',
      status: 'waiting for EU update',
      pdf: 'download',
    },
    {
      source: 'Manual',
      articles: 'GTN3,GTN4',
      supplierName: 'Supplier 2',
      commodities: '123,2345',
      ddsNo: 5678,
      date: '2024-10-11',
      status: 'pending approval',
      pdf: 'download',
    },
    {
      source: 'EDI',
      articles: 'GTN5',
      supplierName: 'Supplier 3',
      commodities: '123,2345',
      ddsNo: 9101,
      date: '2024-10-09',
      status: 'approved',
      pdf: 'download',
    },
  ];

  const data = [
    {
      Commodities: 1801,
      Country: 'SE',
      Producer: 'Producer 1',
      ProductionPlaces: 1000,
      CRA: true,
      GLA: 400,
    },
    {
      Commodities: 1801,
      Country: 'PT',
      Producer: 'Producer 2',
      ProductionPlaces: 300,
      CRA: false,
      GLA: 300,
    },
  ];

  const submit = (e: any, button: any, index: any) => {
    console.log(e, button, index);
  };

  const invalidate = (e: any, button: any, index: any) => {
    console.log(e, button, index);
  };
  const onSearch = (e: any) => {
    console.log(e);
  };
  const buttonData = [
    { label: 'Submit', onClick: submit },
    { label: 'Invalidate', onClick: invalidate },
    // Add more button configurations as needed
  ];
  return (
    <div className="details-container">
      <div className="table-container">
        <div className="table-list-view">
          <TableLayout items={items} isLeftSide={true} onSearch={onSearch} />
        </div>
      </div>
      <div className="detail-list">
        <DetailsLayout
          data={data}
          buttonData={buttonData}
          isCheckBoxDisable={false}
          isTextAreaDisbale={false}
          isDropdown={true}
        />
      </div>
    </div>
  );
};

export default SaveForLater;
