import React, { useEffect, useState } from 'react';
import './pendingApproval.scss';
import TableLayout from 'components/SharedLayout/TableLayout/tableLayout';
import DetailsLayout from 'components/SharedLayout/DetailsLayout/detailsLayout';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
import { getPendingApprovalList, getRiskDetails } from 'Action/pendingApprovalAction';
import { getFEDSAReferenceNumber } from 'utils/common';

const PendingApproval = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { pendingApprovalList, riskDetailList } = useSelector(
    (state: RootState) => state.pendingApprovalDetails
  );
  const [showDetailsData, setShowDetailsData] = useState(false);
  const [listData, setListData] = useState<any>([]);
  const [riskDetailsById, setRiskDetailsById] = useState<any>([]);
  const [ddsReference, setDDSReference] = useState<any>();
  const mapping = {
    isEntryFromAPI: 'Source',
    articles: 'Articles',
    supplierName: 'Supplier Name',
    commodities: 'Commodities',
    dds_RefNum: 'DDS_Ref_Num',
    receivedOn: 'DDS Received On',
    pdf: 'PDF',
  };
  const riskDetailMapping = {
    commodities: 'Commodities',
    country: 'Country',
    productionPlaceCount: 'Production Place',
    cra: 'CRA',
    gla: 'GLA',
  };

  useEffect(() => {
    dispatch(getPendingApprovalList());
  }, []);
  useEffect(() => {
    let items = mappedItems(pendingApprovalList, mapping);
    setListData(items);
  }, [pendingApprovalList]);
  useEffect(() => {
    console.log(riskDetailList);
    let items = mappedItems(riskDetailList, riskDetailMapping);
    setRiskDetailsById(items);
  }, [riskDetailList]);

  const mappedItems = (itemList, mappingKey) => {
    return itemList?.map((item) => {
      return Object.keys(mappingKey).reduce((acc, key) => {
        acc[mappingKey[key]] = item[key] !== undefined ? item[key] : null; // Set to null if the key does not exist
        return acc;
      }, {});
    });
  };

  const saveForLater = (e: any, button: any, index: any) => {};
  const submit = (e: any, button: any, index: any) => {};

  const invalidate = (e: any, button: any, index: any) => {};
  const buttonData = [
    { label: 'Save for later', onClick: saveForLater },
    { label: 'Submit', onClick: submit },
    { label: 'Invalidate', onClick: invalidate },
    // Add more button configurations as needed
  ];
  const onShowDetails = (value, dds_RefNum) => {
    if (dds_RefNum) {
      setDDSReference(dds_RefNum);
      const dds_id = getFEDSAReferenceNumber(pendingApprovalList, dds_RefNum);
      dispatch(getRiskDetails(dds_id));
    }
    setShowDetailsData(value);
  };
  const onSearch = (e) => {
    console.log(e, 'ooo');
  };
  return (
    <div className="details-container">
      <div className="table-container">
        <div className="table-list-view">
          {pendingApprovalList && listData?.length > 0 && (
            <TableLayout
              items={listData}
              isLeftSide={true}
              onShowDetails={onShowDetails}
              pendingApprovalData={pendingApprovalList}
              onSearch={onSearch}
            />
          )}
        </div>
      </div>
      {showDetailsData && riskDetailList?.length > 0 && riskDetailsById?.length > 0 && (
        <div className="detail-list">
          <DetailsLayout
            data={riskDetailsById}
            buttonData={buttonData}
            isCheckBoxDisable={false}
            isTextAreaDisbale={false}
            isDropdown={true}
            ddsReference={ddsReference}
            pendingApprovalData={pendingApprovalList}
          />
        </div>
      )}
    </div>
  );
};

export default PendingApproval;
