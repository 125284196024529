import { combineReducers } from 'redux';
import fileUploadReducer from './fileUploadReducer';
import manualSubmitReducer from './manualSubmitReducer';
import errorReducer from './errorReducer';
import pendingApprovalReducer from './pendingApprovalReducer';
import manualDropDownReducer from './manualDropDownReducer';
import manualValidateReducer from './manualValidateReducer';
export default combineReducers({
  fileUploadDetails: fileUploadReducer,
  manualEntrySubmitData: manualSubmitReducer,
  dropDownMasterData: manualDropDownReducer,
  error: errorReducer,
  pendingApprovalDetails: pendingApprovalReducer,
  manualValidateDetails: manualValidateReducer,
});
