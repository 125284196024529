import { MANUAL_MASTER_DATA } from 'Action/types';

const initialState = {
  masterData: null,
};

export default (state: any, action: any) => {
  if (!state) {
    state = initialState;
  }

  switch (action.type) {
    case MANUAL_MASTER_DATA:
      return {
        ...state,
        masterData: action.payload.data,
      };

    // case FILE_UPLOAD_SUCESSFULLY:
    //   return {
    //     ...state,
    //     fileUploadStatus: action.payload,
    //   };
    default:
      return state;
  }
};
