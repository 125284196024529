import React, { useRef, useEffect, useState } from 'react';
import './commodities.scss';
import Combobox, { MenuItem } from '@ingka/combobox';
import InputField from '@ingka/input-field';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import styled from 'styled-components';
import './commodities.scss';
import Button from '@ingka/button';
import { Select, Option } from '@ingka/select';
import FormField from '@ingka/form-field';
import { masterDataManual } from 'Action/manualDropDownAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/forms/dist/style.css';
import Accordion, { AccordionItem } from '@ingka/accordion';

const CommoditiesDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  justify-content: space-between;
  gap: 10px;
  padding: 5px;
`;

const Commodities = (props: any) => {
  const [selectedCountry, setselectedCountry] = useState<string>('');
  const [selectedUnit, setselectedUnit] = useState<string>('');
  const [selectedHsheader, setselectedHsheader] = useState<string>('');
  const [selectedDescription, setselectedDescription] = useState<string>('');
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [shouldValidate, setShouldValidate] = useState(false);
  const [shouldValidateArea, setShouldValidateArea] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const { masterData } = useSelector((state: RootState) => state.dropDownMasterData);

  useEffect(() => {
    dispatch(masterDataManual());
  }, []);

  const [addPlace, setAddPlace] = useState(false);
  const [isproducerRow, setIsProducerRow] = useState(false);
  const [previousplaceRows, setPreviousPlaceRows] = useState([]);
  const [previousProducerRows, setPreviousProducerRows] = useState([]);

  const prevProps = useRef();

  const handleAddProducer = (index: number, field: any, value: any, event: any | null) => {
    const newProducerRows = props.producerRows.map((row: any, i: any) =>
      i === index ? { ...row, [field]: value } : row
    );
    const producerCountry = newProducerRows[index].producerCountry;
    const productionPlace = newProducerRows[index].productionPlace;
    if (!producerCountry || !productionPlace) {
      setShouldValidate(true);
    } else {
      setShouldValidate(false);
    }
    props.setProducerRows(newProducerRows);
    const selectedCountryName = event.target.value;
    setselectedCountry(selectedCountryName);
  };
  const handleAddProducerRow = (event: any | null) => {
    props.setProducerRows([{ producerName: '', producerCountry: '' }]);
    setIsProducerRow(true);
  };

  const handlePlaceChange = (index: number, field: any, value: any, event: any) => {
    const newProductionPlace = props.productionPlaceRows.map((row: any, i: any) =>
      i === index ? { ...row, [field]: value } : row
    );
    props.setProductionPlaceRows(newProductionPlace);
    const selectedUnitType = event.target.value;
    setselectedUnit(selectedUnitType);

    const type = newProductionPlace[index].type;
    const area = newProductionPlace[index].area;
    console.log(type, 'type');
    if (type === 'Point' && (!area || area > 4)) {
      setShouldValidateArea(true);
    } else {
      setShouldValidateArea(false);
    }
  };

  const handleInputChange = (index: number, field: any, value: any, event: any) => {
    const newCommodityRows = props.commodityRows.map((row: any, i: any) =>
      i === index ? { ...row, [field]: value } : row
    );
    if (field === 'hsHeader') {
      const selectedCommodity = masterData?.commodityMaster.find(
        (commodity) => commodity.hsCode === event.target.value
      );
      newCommodityRows[index].descriptionOfGoods = selectedCommodity?.eudrDesc;
    }
    props.setCommodityRows(newCommodityRows);
    const netWeight = newCommodityRows[index].netWeight;
    const volume = newCommodityRows[index].volume;
    const hsHeader = newCommodityRows[index].hsHeader;
    const numberOfUnits = newCommodityRows[index].numberofUnits;
    const universalUnit = newCommodityRows[index].universalUnit;
    const descriptionOfGoods = newCommodityRows[index].descriptionOfGoods;

    if (!netWeight && !volume && hsHeader && numberOfUnits && universalUnit && descriptionOfGoods) {
      setShouldValidate(true);
    } else {
      setShouldValidate(false);
    }
    // if (!producerCountry || !productionPlace) {
    //   setShouldValidate(true);
    // } else {
    //   setShouldValidate(false);
    // }
    props.setCommodityRows(newCommodityRows);
  };
  const handleAddCommodityRow = () => {
    props.setCommodityRows([
      {
        hsHeader: '',
        descriptionOfGoods: '',
        netWeight: '',
        numberofUnits: '',
        volume: '',
        universalUnit: '',
      },
    ]);
  };
  useEffect(() => {
    // Store current props in ref
    prevProps.current = props;
  });
  const previousProps = prevProps.current;
  const handleAddProducionPlaceRow = () => {
    setPreviousPlaceRows([...previousplaceRows, ...props.productionPlaceRows]);
    props.setProductionPlaceRows([{ productionPlace: '', type: '', coordinates: '', area: '' }]);
    // props.setProductionPlaceRows([...props.productionPlaceRows,previousProps]);
    console.log(props.productionPlaceRows, 'props.productionPlaceRows');
    setAddPlace(true);
  };

  const accordions = (
    <div>
      <div className="commodity" style={{ height: 'auto' }}>
        <Accordion key="tab-4" title="Commodities" id="tab_4">
          {props.commodityRows.map((row, index) => (
            <AccordionItem
              id={`tab_4_${index}`}
              key={index}
              title="Commodities"
              open={props.isAccordionOpen}
            >
              <div className="commodity-tabs">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    marginTop: 10,
                    marginBottom: 10,
                    gap: 10,
                  }}
                >
                  <Button onClick={handleAddCommodityRow} size="xsmall">
                    Add Commodities
                  </Button>
                  <Button onClick={handleAddCommodityRow} size="xsmall">
                    Remove commodities
                  </Button>
                </div>
                <CommoditiesDiv>
                  <Select
                    id={`hscode-${index}`}
                    label="HS header"
                    value={row.hsHeader}
                    onChange={(e) => handleInputChange(index, 'hsHeader', e.target.value, e)}
                    hintText="hs code"
                  >
                    {masterData?.commodityMaster &&
                      masterData.commodityMaster.map((commodity) => (
                        <Option
                          key={commodity.hsCode}
                          value={commodity.hsCode}
                          name={commodity.hsCode}
                        />
                      ))}
                  </Select>
                  <InputField
                    label="Description of goods"
                    iconPosition="leading"
                    id={`description-${index}`}
                    type="text"
                    className="description"
                    value={row.descriptionOfGoods}
                    placeholder="Description of goods"
                    onChange={(e) =>
                      handleInputChange(index, 'descriptionOfGoods', e.target.value, e)
                    }
                  />
                </CommoditiesDiv>
                <div className="quantity-tabs">
                  <FormField
                    style={{ display: 'flex', marginBottom: 0, gap: 10 }}
                    shouldValidate={shouldValidate}
                    valid={shouldValidate ? 'error' : false}
                    validation={{
                      id: 'error-msg-id',
                      msg: 'Either Net Weight or Volume must be provided',
                      type: 'error',
                    }}
                  >
                    <InputField
                      label="netWeight"
                      iconPosition="leading"
                      id={`net-weight-${index}`}
                      type="number"
                      style={{ marginRight: 10 }}
                      value={row.netWeight}
                      placeholder="NetWeight(kg)"
                      onChange={(e) => handleInputChange(index, 'netWeight', e.target.value, e)}
                    />
                    <InputField
                      label="Volume(m3)"
                      iconPosition="leading"
                      id={`volume-${index}`}
                      type="number"
                      style={{ marginRight: 10 }}
                      value={row.volume}
                      placeholder="productname"
                      onChange={(e) => handleInputChange(index, 'volume', e.target.value, e)}
                    />
                  </FormField>

                  {/* <div style={{ position: 'relative' }}> */}
                  <InputField
                    label="Number of units"
                    iconPosition="leading"
                    id={`number-${index}`}
                    type="number"
                    style={{ marginRight: 10 }}
                    value={row.numberofUnits}
                    onChange={(e) => handleInputChange(index, 'numberofUnits', e.target.value, e)}
                    onFocus={() => setShowPlaceholder(false)}
                  />
                  {/* </div> */}
                  <Select
                    id={`unitId-${index}`}
                    label="Universal unit type"
                    value={row.universalUnit}
                    onChange={(e) => handleInputChange(index, 'universalUnit', e.target.value, e)}
                  >
                    {masterData?.universalUnitTypeMaster &&
                      masterData.universalUnitTypeMaster.map((data) => (
                        <Option
                          key={data.unitTypeCode}
                          value={data.unitTypeCode}
                          name={data.unitTypeCode}
                        />
                      ))}
                  </Select>
                </div>
              </div>

              <span style={{ fontSize: 20, fontWeight: 800 }}>Producers</span>
              {index === 0 && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button onClick={handleAddProducerRow} size="xsmall">
                    Add Producer
                  </Button>
                </div>
              )}
              {props.producerRows.map((row, index) => (
                <div key={index} style={{ display: 'flex', gap: 10 }}>
                  <InputField
                    label="Producer Name"
                    iconPosition="leading"
                    id={`producer-name-${index}`}
                    type="text"
                    style={{ marginRight: 10, width: 100 }}
                    value={row.producerName}
                    onChange={(e) => handleAddProducer(index, 'producerName', e.target.value, e)}
                  />
                  <Select
                    id={`countryId-${index}`}
                    label="Country"
                    value={row.producerCountry}
                    style={{ maxHeight: 100 }}
                    onChange={(e) => handleAddProducer(index, 'producerCountry', e.target.value, e)}
                  >
                    {masterData?.countryMaster &&
                      masterData.countryMaster.map((data) => (
                        <Option
                          key={data.countryName}
                          value={data.countryCode}
                          name={data.countryName}
                        />
                      ))}
                  </Select>
                  {props.productionPlaceRows[index] && (
                    <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                      <div className="productionPlace">
                        {isproducerRow &&
                          previousProducerRows?.map((row, index) => (
                            <div
                              className="text"
                              style={{ fontSize: 15, fontWeight: 800 }}
                              key={index}
                            >
                              {row.productionPlace},{row.type}[{row.coordinates}],{row.area}{' '}
                            </div>
                          ))}

                        <>
                          <div style={{ marginTop: 50 }}>
                            <span style={{ fontSize: 20, fontWeight: 800 }}>Production Place</span>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Button onClick={handleAddProducionPlaceRow} size="xsmall">
                                Add Production Place
                              </Button>
                            </div>
                          </div>
                          {props.productionPlaceRows.map((row, index) => (
                            <div key={index}>
                              <div className="production-place">
                                <InputField
                                  label="Production place"
                                  iconPosition="leading"
                                  id={`production-place-${index}`}
                                  type="text"
                                  style={{ marginRight: 10, width: 300 }}
                                  value={
                                    row.productionPlace ||
                                    props.productionPlaceRows[index].productionPlace
                                  }
                                  onChange={(e) =>
                                    handlePlaceChange(index, 'productionPlace', e.target.value, e)
                                  }
                                />
                                <Select
                                  id={`geoId-${index}`}
                                  label="Geo Json type"
                                  value={row.type || props.productionPlaceRows[index].type}
                                  onChange={(e) =>
                                    handlePlaceChange(index, 'type', e.target.value, e)
                                  }
                                >
                                  {masterData.geoJsonTypeMaster &&
                                    masterData.geoJsonTypeMaster.map((geojsontype) => (
                                      <Option
                                        key={geojsontype.geoJsonType}
                                        value={geojsontype.geoJsonType}
                                        name={geojsontype.geoJsonType}
                                      />
                                    ))}
                                </Select>
                              </div>

                              <div style={{ display: 'flex', gap: 10 }}>
                                <InputField
                                  label="Geo json coordinates"
                                  iconPosition="leading"
                                  id={`coordinates-${index}`}
                                  type="text"
                                  style={{ marginRight: 10, width: 518 }}
                                  value={
                                    row.coordinatesString ||
                                    props.productionPlaceRows[index].coordinatesString
                                  }
                                  onChange={(e) =>
                                    handlePlaceChange(index, 'coordinatesString', e.target.value, e)
                                  }
                                />
                                <FormField
                                  shouldValidate={shouldValidateArea}
                                  valid={shouldValidateArea ? 'error' : false}
                                  validation={{
                                    id: 'error-msg-id',
                                    msg: 'Area is required for point coordinates and cannot exceed 4',
                                    type: 'error',
                                  }}
                                >
                                  <InputField
                                    label="Area"
                                    iconPosition="leading"
                                    id={`area-${index}`}
                                    type="number"
                                    style={{ marginRight: 10 }}
                                    value={row.area || props.productionPlaceRows[index].area}
                                    placeholder="Description of goods"
                                    onChange={(e) =>
                                      handlePlaceChange(index, 'area', e.target.value, e)
                                    }
                                  />
                                </FormField>
                                {/* {shouldValidate && (
                    <div className="error-message" style={{ color: 'red' }}>
                      Area is required for point coordinates and cannot exceed 4
                    </div>
                  )} */}
                              </div>
                            </div>
                          ))}
                        </>
                        {addPlace &&
                          previousplaceRows?.map((row, index) => (
                            <div
                              key={index}
                              className="text"
                              style={{ fontSize: 15, fontWeight: 800 }}
                            >
                              {row.productionPlace},{row.type}[{row.coordinates}],{row.area}{' '}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </AccordionItem>
          ))}
        </Accordion>
        <div style={{ marginLeft: 20 }}></div>
      </div>
    </div>
  );
  return <div>{accordions}</div>;
};

export default Commodities;
